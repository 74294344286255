import { Button, Container, Divider, FormControl, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import Swal from "sweetalert2";

export const Home = () => {
	const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
	const [form, setForm] = React.useState<{
		fname: string;
		lname: string;
		phoneNo: string;
		email: string;
		password: string;
	}>({
		fname: "",
		lname: "",
		phoneNo: "",
		email: "",
		password: "",
	});

	const setFormValue = (field: string, value: string) => {
		setForm({
			...form,
			[field]: value,
		});
	};

	const register = () => {
		setIsSubmitted(true);
		if (!form.fname || !form.lname || !form.phoneNo || !form.email || !form.password) {
			Swal.fire("Register error", "Please enter all the required field", "error");
		} else {
			setIsSubmitted(false);

			fetch("https://api.ampious.in/api/gym-owner/register", {
				method: "post",
				body: JSON.stringify(form),
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((response) => {
					console.log("eee", response);
					if (response.res) {
						Swal.fire("Register successfully", "You have been registered succesfully, Please check your mail for more detaisl", "success");
					}
					if (response.code === 11000) {
						Swal.fire("Register error", "This email id/phone number is already registered, please login or use another email", "error");
					}
				})
				.catch((error) => {
					console.log("error", error);
				});
		}
	};

	return (
		<>
			<Grid container justifyContent={"space-between"} direction={{ lg: "row", sm: "column", xs: "column" }}>
				<Grid item container flex={0.6}>
					<Grid item justifyContent={"center"} paddingTop={1}>
						<img alt="ampious logo" src="/logo.png" width={70} />
					</Grid>
					<Grid item>
						<h2 style={{ textTransform: "uppercase", paddingBottom: 0, marginBottom: 0, letterSpacing: 3 }}>Ampious</h2>
						<h4 style={{ textTransform: "uppercase", marginTop: 0, paddingTop: 0 }}>Gym management system</h4>
					</Grid>
				</Grid>
				<Grid item justifyContent={"center"} alignContent={"center"} alignItems={"flex-end"} textAlign={"right"} flex={0.6}>
					<Button
						variant="text"
						color="error"
						fullWidth
						style={{ width: 100 }}
						onClick={() => {
							window.open("https://app.ampious.in/public/login", "_blank");
						}}
					>
						Login
					</Button>
				</Grid>
			</Grid>
			<Divider />
			<Container maxWidth="lg">
				<Grid container justifyContent={"space-between"} direction={"row"} marginTop={5} flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }} gap={2}>
					<Grid item container direction={"column"} flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }}>
						<Grid item>
							<h1 className="font-roboto" style={{ fontWeight: 300, marginBottom: 10, marginTop: 0, padding: "0 30px", fontSize: 26 }}>
								Ampious: Gym Management System
							</h1>
						</Grid>
						{/* <Divider /> */}
						<Grid item style={{ marginTop: 0, padding: "0 30px" }}>
							<h3 style={{ lineHeight: "30px" }}>Simplify fitness management with our gym management software</h3>
							<p style={{ lineHeight: "30px" }}>Manage client memberships and packages, schedule training sessions, track staff and customer attendance,and evaluate staff performance based on key metrics. Our gym management software is designed to make it easy to manage and administrate fitness clubs of all sizes. Manage visitors for popular classes, issue daily passes to trial customer</p>
						</Grid>
					</Grid>

					<Grid item flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }} justifyContent={"center"} alignContent={"center"} alignItems={"center"} display={"flex"}>
						<div style={{ backgroundColor: "#ebefff", padding: "10px 30px", border: "1px solid #bdc3eb", borderRadius: 10, paddingBottom: 40 }}>
							<h3 style={{ textAlign: "center", textTransform: "uppercase", paddingBottom: 0, marginBottom: 0, letterSpacing: 3 }}>Start your 7-days free trial</h3>
							<FormControl fullWidth style={{ marginTop: 20 }} error={isSubmitted && !form.fname}>
                                <TextField
                                    error={isSubmitted && !form.fname}
									fullWidth
									placeholder="First Name"
									style={{ backgroundColor: "#fff" }}
									onChange={(event) => {
										setFormValue("fname", event.target.value);
									}}
								/>
							</FormControl>
							<FormControl fullWidth style={{ marginTop: 10 }} error={isSubmitted && !form.lname}>
                                <TextField
                                    error={isSubmitted && !form.lname}
									fullWidth
									placeholder="Last Name"
									style={{ backgroundColor: "#fff" }}
									onChange={(event) => {
										setFormValue("lname", event.target.value);
									}}
								/>
							</FormControl>
							<FormControl fullWidth style={{ marginTop: 10 }} error={isSubmitted && !form.email}>
                                <TextField
                                    error={isSubmitted && !form.email}
									fullWidth
									placeholder="Email"
									style={{ backgroundColor: "#fff" }}
									onChange={(event) => {
										setFormValue("email", event.target.value);
									}}
								/>
							</FormControl>
							<FormControl fullWidth style={{ marginTop: 10 }} error={isSubmitted && !form.phoneNo}>
                                <TextField
                                    error={isSubmitted && !form.phoneNo}
									fullWidth
									slotProps={{
										htmlInput: {
											maxLength: 12,
										},
									}}
									placeholder="Phone Number with 91"
									style={{ backgroundColor: "#fff" }}
									onChange={(event) => {
										setFormValue("phoneNo", event.target.value);
									}}
								/>
							</FormControl>
							<FormControl fullWidth style={{ marginTop: 10 }} error={isSubmitted && !form.password}>
                                <TextField
                                    error={isSubmitted && !form.password}
									security="password"
									fullWidth
									slotProps={{
										htmlInput: {
											type: "password",
										},
									}}
									placeholder="Password"
									style={{ backgroundColor: "#fff" }}
									onChange={(event) => {
										setFormValue("password", event.target.value);
									}}
								/>
							</FormControl>
							<FormControl fullWidth style={{ marginTop: 10 }}>
								<Button style={{ backgroundColor: "red", color: "#fff" }} onClick={register}>
									Register
								</Button>
								<p style={{ textAlign: "center" }}>
									By Registering, you agree to{" "}
									<a href="https://app.ampious.in/public/terms-and-conditions" target="_blank" rel="noreferrer">
										terms
									</a>{" "}
									and{" "}
									<a href="https://app.ampious.in/public/privacy-policy" target="_blank" rel="noreferrer">
										privacy policy
									</a>{" "}
								</p>
							</FormControl>
						</div>
					</Grid>
				</Grid>

				<Grid flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }} container style={{ backgroundColor: "#f2f2f2", marginTop: 20, borderRadius: 20, paddingBottom: 20 }} direction="column">
					<Grid flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }} item justifyContent={"center"} alignItems={"center"}>
						<h3 style={{ lineHeight: "30px", fontWeight: 400, textTransform: "uppercase", textAlign: "center" }}>Feature of Ampious</h3>
					</Grid>

					<Grid flex={{ xs: 1, lg: 0.6, sm: 1, md: 1 }} item container justifyContent={"center"} gap={2} overflow={"auto"}>
						<Grid item>
							<img alt="gym management software for small gyms features" src="dashboard.png" width={300}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Dashboard</h3>
						</Grid>
						<Grid item paddingLeft={2} paddingTop={0}>
							<img alt="gym management software for small gyms features" src="visitor-details.png" width={300}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Visitor Details</h3>
						</Grid>
						<Grid item paddingLeft={2} paddingTop={0}>
							<img alt="gym management software for small gyms features" src="workout-creation.png" width={300}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Create Workouts</h3>
						</Grid>
						<Grid item paddingLeft={2} paddingTop={0}>
							<img alt="gym management software for small gyms features" src="workout-assignment.png" width={300} height={225}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Assignment workout to customers</h3>
						</Grid>

						<Grid item paddingLeft={2} paddingTop={0}>
							<img alt="gym management software for small gyms features" src="Whatsapp.png" width={300}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Whatsapp notification</h3>
						</Grid>

						<Grid item paddingLeft={2}>
							<img alt="gym management software for small gyms features" src="bulk-upload.png" width={300} height={220}  style={{border:'1px solid #000', borderRadius:10,padding:2}} />
							<h3 style={{ textAlign: "center", fontWeight: 300,fontSize:14, marginBottom: 2, marginTop: 2 }}>Bulk Upload Customers</h3>
						</Grid>
					</Grid>
				</Grid>

				<Grid container style={{ alignContent: "center", justifyContent: "center" }}>
					<Grid item>
						<h2 style={{ fontSize: 27, textAlign: "center", marginBottom: 0 }}>Let gym management software do the heavy lifting for you</h2>
					</Grid>
					<Grid item container direction={"row"}>
						<Grid item style={{ backgroundColor: "#edf5ff", padding: 30, borderRadius: 10, margin: 10, width: "100%" }}>
							<h3 style={{ fontSize: 27 }}>Membership management</h3>
							<p>Manage your gym’s entire client cycle, from member signups to membership contract renewal and payment processing. Our gym management application lets you categorize clients into different profiles and helps you stay updated with their favorite workouts, birthdays, training sessions, and diets.</p>
						</Grid>
						<Grid item style={{ backgroundColor: "#edf5ff", padding: 30, borderRadius: 10, margin: 10, width: "100%" }}>
							<h3 style={{ fontSize: 27 }}>Class scheduling</h3>
							<p>This app is ideal for creating schedules for clients and ensuring that trainers are available. You can track the number of members in various classes and analyze the busiest times of the day to manage your trainers' schedules more efficiently. Opening new sessions, monitoring staff availability, rescheduling classes—everything can be done with our simple drag and drop interface.</p>
						</Grid>
						<Grid item style={{ backgroundColor: "#edf5ff", padding: 30, borderRadius: 10, margin: 10, width: "100%" }}>
							<h3 style={{ fontSize: 27 }}>Health updates </h3>
							<p>With this feature, you can provide your clients with regular fitness notifications. Analyze their activities over a period and suggest suitable training, or map your members’ progress to help them focus on their fitness goals.</p>
						</Grid>
						<Grid item style={{ backgroundColor: "#edf5ff", padding: 30, borderRadius: 10, margin: 10, width: "100%" }}>
							<h3 style={{ fontSize: 27 }}>Notifications</h3>
							<p>Create 1 to 1 communication to your customers via whatsapp and E-Mail</p>
						</Grid>
						<Grid item style={{ backgroundColor: "#edf5ff", padding: 30, borderRadius: 10, margin: 10, width: "100%" }}>
							<h3 style={{ fontSize: 27 }}>Visitors</h3>
							<p>Save Visitors details and follow them up by offline calling or using in build whatsapp and mailing process</p>
						</Grid>
					</Grid>
				</Grid>
			</Container>

			<Grid container style={{ backgroundColor: "#000", height: 200 }} justifyContent={"center"} direction={"column"} alignContent={"center"}>
				<Grid item alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
					<h2 className="font-roboto" style={{ color: "#fff" }}>
						Kickstart your fitness journey with our gym management software
					</h2>
				</Grid>
				<Grid item justifyContent={"center"} alignContent={"center"} alignItems={"center"} display={"flex"}>
					<Button
						onClick={() => {
							window.open("https://app.ampious.in/public/login", "_blank");
						}}
						variant="contained"
						style={{ backgroundColor: "red", alignSelf: "center" }}
					>
						Explore Ampious
					</Button>
				</Grid>
			</Grid>
			<Grid container width={"100%"} style={{ backgroundColor: "#000" }} gap={2} justifyContent={"center"}>
				<Grid item>
					<p style={{ color: "#fff" }}>Ph: 9026631323</p>
				</Grid>
				<Grid item>
					<p style={{ color: "#fff" }}>Email: support@ampious.in</p>
				</Grid>
			</Grid>
		</>
	);
};
